<template>
  <div class="wrap">
    <div class="goBack" @click="$emit('goBack')">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
      </svg>
      назад
    </div>

    <h1>
      <span>Задача: формирование заключения о предоставлении сервиса</span>
    </h1>

    <p>{{ application.lastName }} {{ application.firstName }} {{ application.middleName }}</p>


    <h6>А) Соответствие фактического и целевого профилей компетенций гражданина:</h6>

    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Наименование компетенции</th>
        <th>Отметка о наличии компетенции у гражданина</th>
        <th>Требуемый уровень развития</th>
        <th>Отметка о соответствии уровня развития</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(requiredSkill, requiredSkillIndex) in assessment.desiredProfession.requiredSkills"
          :key="'trdesiredskills4_'+requiredSkillIndex">
        <td>{{ requiredSkillIndex + 1 }}</td>
        <td>{{ requiredSkill.skill.name }}</td>
        <td>{{ hasRequiredSkill(requiredSkill) ? 'Да' : 'Нет' }}</td>
        <td>{{ requiredSkill.mark }}</td>
        <td>{{ checkRequiredSkill(requiredSkill) ? 'Да' : 'Нет' }}</td>
      </tr>
      </tbody>
    </table>

    <h6>Б) Соответствие фактических и целевых сведений об образовании:</h6>

    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Сведения об образовании</th>
        <th>Отметка о наличии</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>
          Образование (при наличии) (из целевого профиля)
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-on="on" v-bind="attrs">mdi-help-circle</v-icon>
            </template>
            Требуется: {{ EducationLevels[assessment.desiredProfession.reqEducation.educationLevel] }}<br>
            Имеется: {{ EducationLevels[application.educationInfo.educationLevel] }}
          </v-tooltip>
        </td>
        <td>
          <v-checkbox v-model="assessment.educationLevelCheck" :readonly="onlyView"></v-checkbox>
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td>
          Квалификация по диплому (при наличии) (из целевого профиля)
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-on="on" v-bind="attrs">mdi-help-circle</v-icon>
            </template>
            Требуется: {{ assessment.desiredProfession.reqEducation.qualification }}<br>
            Имеется: {{ application.educationInfo.qualification }}
          </v-tooltip>
        </td>
        <td>
          <v-checkbox v-model="assessment.qualificationCheck" :readonly="onlyView"></v-checkbox>
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>
          Специальность по диплому (при наличии) (из целевого профиля)
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-on="on" v-bind="attrs">mdi-help-circle</v-icon>
            </template>
            Требуется: {{ assessment.desiredProfession.reqEducation.diplomaSpeciality }}<br>
            Имеется: {{ application.educationInfo.diplomaSpeciality }}
          </v-tooltip>
        </td>
        <td>
          <v-checkbox v-model="assessment.diplomaSpecialityCheck" :readonly="onlyView"></v-checkbox>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="buttons">
      <v-btn @click="download()">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17 5H16V0H4V5H3C1.34 5 0 6.34 0 8V14H4V18H16V14H20V8C20 6.34 18.66 5 17 5ZM6 2H14V5H6V2ZM14 16H6V12H14V16ZM16 12V10H4V12H2V8C2 7.45 2.45 7 3 7H17C17.55 7 18 7.45 18 8V12H16Z"
              fill="#69B3E7"/>
          <path
              d="M16 9.5C16.5523 9.5 17 9.05228 17 8.5C17 7.94772 16.5523 7.5 16 7.5C15.4477 7.5 15 7.94772 15 8.5C15 9.05228 15.4477 9.5 16 9.5Z"
              fill="#69B3E7"/>
        </svg>

        СКАЧАТЬ
      </v-btn>
      <v-btn disabled>
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
              fill="#69B3E7"/>
        </svg>

        ОТПРАВИТЬ НА ПОЧТУ
      </v-btn>
      <v-btn color="blue" style="width: 360px" @click="submit()" :disabled="onlyView">ЗАФИКСИРОВАТЬ ОКАЗАНИЕ СЕРВИСА</v-btn>
    </div>
  </div>
</template>

<script>
import {EducationLevels} from "@/modules/NSI";
import api from "@/modules/api";

export default {
  name: "AssessmentComponent",
  props: ['application', 'assessment', 'onlyView'],
  data() {
    return {
      EducationLevels: EducationLevels,
    }
  },
  methods: {
    hasRequiredSkill(requiredSkill) {
      if (this.findSkillInAssessmentEntries(requiredSkill.skill) != null)
        return true
      return false
    },

    checkRequiredSkill(requiredSkill) {
      let availableSkill = this.findSkillInAssessmentEntries(requiredSkill.skill)
      return availableSkill != null && availableSkill.mark >= requiredSkill.mark;
    },

    findSkillInAssessmentEntries(skill) {
      for (let e of this.assessment.assessmentEntries) {
        if (e.skill.id === skill.id || e.skill.name === skill.name)
          return e
      }
    },

    async download(){
      let req = await api.postJson("/report/", this.assessment, 'blob')
      if(req.ok) {
        let urln = URL.createObjectURL(req.payload)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = urln
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(urln)
      } else
        alert("Ошибка получения данных от сервера")
    },

    async submit() {
      this.assessment.localDateTime = new Date()
      this.assessment.localDateTime.setTime(this.assessment.localDateTime.getTime() - this.assessment.localDateTime.getTimezoneOffset() * 60 * 1000)
      console.log(this.assessment)
      let req = await api.postJson("/assessments/", this.assessment)
      if (req.ok) {
        console.log(req.payload)
        this.$router.push('/')
      } else alert('Ошибка сохранения!')
    }
  }
}
</script>

<style scoped>
</style>
