export const EducationLevels = {
    NO_REQ: "Не указано",
    SCHOOL: "Среднее",
    MIDSPEC: "Среднее профессиональное",
    PREHIGHER: "Неоконченное высшее",
    HIGHER: "Высшее"
}

export const ApplicationMethods = {
    APP: 'По заявлению',
    SUGG: 'Предложение сотрудника ЦЗН'
}

export const AssessmentMethods = {
    SELF: 'Самооценка',
    CZN: 'Оценка ЦЗН'
}

export function mapToObject(obj) {
    let res = []
    for (let e in obj)
        res.push({ title: obj[e], value: e })
    return res
}

export function reverseDict(dict){
    let res = {}
    for(let e in dict)
        res[dict[e]] = e
    return res
}