var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"goBack",on:{"click":function($event){return _vm.$emit('goBack')}}},[_c('svg',{attrs:{"width":"6","height":"10","viewBox":"0 0 6 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z","fill":"#0033A0"}})]),_vm._v(" назад ")]),_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.application.lastName)+" "+_vm._s(_vm.application.firstName)+" "+_vm._s(_vm.application.middleName))]),_c('h6',[_vm._v("А) Соответствие фактического и целевого профилей компетенций гражданина:")]),_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.assessment.desiredProfession.requiredSkills),function(requiredSkill,requiredSkillIndex){return _c('tr',{key:'trdesiredskills4_'+requiredSkillIndex},[_c('td',[_vm._v(_vm._s(requiredSkillIndex + 1))]),_c('td',[_vm._v(_vm._s(requiredSkill.skill.name))]),_c('td',[_vm._v(_vm._s(_vm.hasRequiredSkill(requiredSkill) ? 'Да' : 'Нет'))]),_c('td',[_vm._v(_vm._s(requiredSkill.mark))]),_c('td',[_vm._v(_vm._s(_vm.checkRequiredSkill(requiredSkill) ? 'Да' : 'Нет'))])])}),0)]),_c('h6',[_vm._v("Б) Соответствие фактических и целевых сведений об образовании:")]),_c('table',{staticClass:"table"},[_vm._m(2),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v(" Образование (при наличии) (из целевого профиля) "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_vm._v(" Требуется: "+_vm._s(_vm.EducationLevels[_vm.assessment.desiredProfession.reqEducation.educationLevel])),_c('br'),_vm._v(" Имеется: "+_vm._s(_vm.EducationLevels[_vm.application.educationInfo.educationLevel])+" ")])],1),_c('td',[_c('v-checkbox',{attrs:{"readonly":_vm.onlyView},model:{value:(_vm.assessment.educationLevelCheck),callback:function ($$v) {_vm.$set(_vm.assessment, "educationLevelCheck", $$v)},expression:"assessment.educationLevelCheck"}})],1)]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v(" Квалификация по диплому (при наличии) (из целевого профиля) "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_vm._v(" Требуется: "+_vm._s(_vm.assessment.desiredProfession.reqEducation.qualification)),_c('br'),_vm._v(" Имеется: "+_vm._s(_vm.application.educationInfo.qualification)+" ")])],1),_c('td',[_c('v-checkbox',{attrs:{"readonly":_vm.onlyView},model:{value:(_vm.assessment.qualificationCheck),callback:function ($$v) {_vm.$set(_vm.assessment, "qualificationCheck", $$v)},expression:"assessment.qualificationCheck"}})],1)]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v(" Специальность по диплому (при наличии) (из целевого профиля) "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_vm._v(" Требуется: "+_vm._s(_vm.assessment.desiredProfession.reqEducation.diplomaSpeciality)),_c('br'),_vm._v(" Имеется: "+_vm._s(_vm.application.educationInfo.diplomaSpeciality)+" ")])],1),_c('td',[_c('v-checkbox',{attrs:{"readonly":_vm.onlyView},model:{value:(_vm.assessment.diplomaSpecialityCheck),callback:function ($$v) {_vm.$set(_vm.assessment, "diplomaSpecialityCheck", $$v)},expression:"assessment.diplomaSpecialityCheck"}})],1)])])]),_c('div',{staticClass:"buttons"},[_c('v-btn',{on:{"click":function($event){return _vm.download()}}},[_c('svg',{attrs:{"width":"20","height":"18","viewBox":"0 0 20 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17 5H16V0H4V5H3C1.34 5 0 6.34 0 8V14H4V18H16V14H20V8C20 6.34 18.66 5 17 5ZM6 2H14V5H6V2ZM14 16H6V12H14V16ZM16 12V10H4V12H2V8C2 7.45 2.45 7 3 7H17C17.55 7 18 7.45 18 8V12H16Z","fill":"#69B3E7"}}),_c('path',{attrs:{"d":"M16 9.5C16.5523 9.5 17 9.05228 17 8.5C17 7.94772 16.5523 7.5 16 7.5C15.4477 7.5 15 7.94772 15 8.5C15 9.05228 15.4477 9.5 16 9.5Z","fill":"#69B3E7"}})]),_vm._v(" СКАЧАТЬ ")]),_c('v-btn',{attrs:{"disabled":""}},[_c('svg',{attrs:{"width":"20","height":"16","viewBox":"0 0 20 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z","fill":"#69B3E7"}})]),_vm._v(" ОТПРАВИТЬ НА ПОЧТУ ")]),_c('v-btn',{staticStyle:{"width":"360px"},attrs:{"color":"blue","disabled":_vm.onlyView},on:{"click":function($event){return _vm.submit()}}},[_vm._v("ЗАФИКСИРОВАТЬ ОКАЗАНИЕ СЕРВИСА")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('span',[_vm._v("Задача: формирование заключения о предоставлении сервиса")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("№")]),_c('th',[_vm._v("Наименование компетенции")]),_c('th',[_vm._v("Отметка о наличии компетенции у гражданина")]),_c('th',[_vm._v("Требуемый уровень развития")]),_c('th',[_vm._v("Отметка о соответствии уровня развития")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("№")]),_c('th',[_vm._v("Сведения об образовании")]),_c('th',[_vm._v("Отметка о наличии")])])])}]

export { render, staticRenderFns }